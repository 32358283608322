import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import { FieldPhoneNumberInput, FieldTextInput, IconSpinner, Modal, PrimaryButton } from '../../components';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import '../../styles/phoneNumberInput.css';
import { checkIfNumberExist, sendOtp, verifyOtp } from '../../util/api';
import IconCard from '../../components/SavedCardDetails/IconCard/IconCard';
import css from '../AuthenticationPage/SignupForm/SignupForm.module.css';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { useDispatch } from 'react-redux';

const UserFieldPhoneNumber = props => {
  const { rootClassName, className, formId, formName, userTypeConfig, intl, phoneNumber, isVerified, form, values } = props;

  const dispatch = useDispatch()
  const [invalidPhoneValue, setInvalidPhoneValue] = useState();
  const [isVerifying, setIsVerifying] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);

  const { displayInSignUp, required } = userTypeConfig?.phoneNumberSettings || {};
  const isDisabled = userTypeConfig?.defaultUserFields?.phoneNumber === false;
  const isAllowedInSignUp = displayInSignUp === true;

  if (isDisabled || !isAllowedInSignUp) {
    return null;
  }

  const phoneNumberPlaceholder = intl.formatMessage({
    id: 'ContactDetailsForm.phonePlaceholder',
  });
  const phoneLabel = intl.formatMessage({
    id: 'ContactDetailsForm.phoneLabel',
  });

  const isRequired = required === true;
  const validateMaybe = isRequired
    ? {
      validate: validators.required(
        intl.formatMessage({
          id: `${formName}.phoneNumberRequired`,
        })
      ),
    }
    : {};

  const changePhoneValue = () => {
    if (phoneNumber) {
      const formattedNumber = formatPhoneNumberIntl(phoneNumber)
      if (isValidPhoneNumber(formattedNumber)) {
        setInvalidPhoneValue(false)
      } else {
        setInvalidPhoneValue('Invalid phone number')
      }
    }
  }

  const handleVerifyNumber = async () => {
    try {
      setIsVerifying(true)
      const { phoneNumberExists } = await checkIfNumberExist({ phoneNumber });

      if (phoneNumberExists) {
        throw new Error('Phone number exist! Please try different number.')
      }

      const response = await sendOtp({ phoneNumber });
      if (response.status === 'pending') {
        setIsVerifying(false)
        setOpenOtpModal(true)
      }
      else throw new Error('Failed!')
    }
    catch (error) {
      setIsVerifying(false)
      setInvalidPhoneValue(`${error}`)
    }
  }

  const handleChangeInput = (e) => {
    const regex = /^\d{0,6}$/;
    const value = e.target.value;

    if (regex.test(value)) {
      form.change('otp', value);
    }
  }

  const handleVerifyOtp = async () => {
    try {
      setIsLoading(true)
      const response = await verifyOtp({ phoneNumber, otp: values?.otp })

      if (response.status === 'approved') {
        setIsLoading(false)
        setOpenOtpModal(false)
        form.change('isVerified', 'verified')
      }
      else throw new Error('Failed!')
    }
    catch (error) {
      setIsLoading(false)
      setInvalidPhoneValue(`${error}`)
    }
  }

  return (
    // <FieldPhoneNumberInput
    //   className={classNames(className, { [rootClassName]: !!rootClassName })}
    //   type="text"
    //   id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
    //   name="phoneNumber"
    //   label={intl.formatMessage({
    //     id: `${formName}.phoneNumberLabel`,
    //   })}
    //   placeholder={intl.formatMessage({
    //     id: `${formName}.phoneNumberPlaceholder`,
    //   })}
    //   {...validateMaybe}
    // />
    <div className={css.phoneNumberInput}>
      <label>{phoneLabel}</label>
      <PhoneInput
        className={css.fieldTextInput}
        placeholder={phoneNumberPlaceholder}
        value={formatPhoneNumberIntl(phoneNumber)}
        onChange={value => {
          form.change('phoneNumber', value)
          setInvalidPhoneValue(false)
        }}
        error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
        onBlur={changePhoneValue}
        defaultCountry='US'
      />
      {isVerified ? (
        <div className={css.verifyText}>
          Verified
          <IconCard brand='check' />
        </div>
      ) : (
        <>
          {(phoneNumber && !invalidPhoneValue) && (
            <div className={css.verifyText} onClick={handleVerifyNumber}>
              {isVerifying ? <IconSpinner /> : 'Verify'}
            </div>
          )}
        </>
      )}
      {invalidPhoneValue &&
        <p className={css.invalidPhoneValue}>
          {invalidPhoneValue}
        </p>
      }

      <Modal
        id="AuthenticationPage.tos"
        isOpen={openOtpModal}
        onClose={() => setOpenOtpModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))}
      >
        <div className={css.optModal}>
          <FieldTextInput
            type="text"
            className={css.otp}
            id={formId ? `${formId}.otp` : 'otp'}
            name="otp"
            label='Enter OTP'
            onChange={handleChangeInput}
          />
          <PrimaryButton
            type="button"
            onClick={handleVerifyOtp}
            disabled={values?.otp?.length !== 6}
            inProgress={isLoading}
          >
            Submit
          </PrimaryButton>
          {invalidPhoneValue &&
            <p className={css.invalidPhoneValue}>
              {invalidPhoneValue}
            </p>
          }
        </div>
      </Modal>
    </div>
  );
};

UserFieldPhoneNumber.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

UserFieldPhoneNumber.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  formName: string.isRequired,
  userTypeConfig: propTypes.userType.isRequired,
  intl: intlShape.isRequired,
};

export default UserFieldPhoneNumber;
